<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Credits</h1>
  </BaseTableWrapper>
</template>

<script>
import credits from '@/services/credits'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import { currencyFilter } from '@/utils/currency'

const columns = [
  // {
  //   _t_id: '6cf09066',
  //   prop: '/',
  //   text: 'Actions',
  //   component: wrappedBaseActions,
  //   sort: false,
  //   filter: false,
  //   detail: false,
  //   type: 'actions',
  // },
  {
    _t_id: '3582f6d4',
    prop: 'transactionId',
    text: 'Transaction ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'transactionId',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: '3582f940',
    prop: 'transactionType',
    text: 'Transaction Type',
    sort: true,
    filter: true,
    type: 'string',
    filterType: 'eq',
    sortProp: 'transactionType',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: '3582fa8a',
    prop: 'amount',
    text: 'Amount',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'amount',
    defaultSort: true,
    computedText: (item) => currencyFilter(item),
    detail: false,
  },
  {
    _t_id: '358306ec',
    prop: 'companyName',
    text: 'Company Name',
    sort: true,
    filter: true,
    type: 'string',
    filterType: 'contains',
    sortProp: 'companyName',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: '3582fe90',
    prop: 'account1',
    text: 'Account - Primary',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'account1',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: '35830002',
    prop: 'account2',
    text: 'Account - Secondary',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'account2',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: '3583012e',
    prop: 'createdOn',
    text: 'Created On',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'createdOn',
    defaultSort: true,
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    predefined: deepClone(datePredefined),
    detail: false,
  },
  {
    _t_id: '358303c2',
    prop: 'createdBy',
    text: 'Created By',
    sort: true,
    type: 'text',
    filter: true,
    filterType: 'contains',
    sortProp: 'createdBy/lastName',
    detail: false,
  },
  {
    _t_id: '358305ac',
    prop: 'managedId',
    text: 'Reservation ID',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'managedId',
    defaultSort: true,
    displayType: 'clickable-reservation-broker',
    component: GenericActionableColumn,
    detail: false,
  },
  {
    _t_id: '358306ex',
    prop: 'paymentId',
    text: 'Payment ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'paymentId',
    defaultSort: true,
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Credits',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detailKeyId: 'transactionId',
        tableId: 'credits_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'Credits',
        action: (params) => credits.getCredits(params),
      },
    }
  },
}
</script>
